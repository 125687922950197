import { useEffect, useState } from "react";
import { routeURL } from "../../api/api";
import { Route } from "../../api/types";
import { useHttpData } from "../../hooks/http";
import { buildStatusFromAlerts } from "../Alert";
import "./ListOfRouteLogos.css";
import RouteLogo from "./RouteLogo";
import { Link } from "react-router-dom";

export type ListOfRouteLogosProps = {
  routeIds: string[];
  skipExpress: boolean;
  addLinks: boolean;
};


export default function ListOfRouteLogos(props: ListOfRouteLogosProps) {
  let routeIds = sortRouteIds(props.routeIds);
  let routeLogos = [];
  for (const routeId of routeIds) {
    if (props.skipExpress && routeId.substr(-1, 1) === "X") {
      continue;
    }
    routeLogos.push(
      <LogoWrapper
        key={"logowrapper" + routeId}
        routeId={routeId}
        addLinks={props.addLinks}
      />
    );
  }
  return <div className="ListOfRouteLogos">{routeLogos}</div>;
}

export type LogoWrapperProps = {
  routeId: string
  addLinks: boolean
};

function LogoWrapper(props: LogoWrapperProps) {
  const routeId = props.routeId;
  let statusClasses = "statusCircle";
  const routeData = useHttpData(routeURL(routeId), 10_000, Route.fromJSON);

  const [_, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  let color = undefined;
  if (routeData.error) {
    color = "Red";
  } else if (routeData.response) {
    let dataAgeSec = (Date.now() - (routeData.time ?? 0)) / 1000;
    if (dataAgeSec > 30) {
      color = "Gray";
    } else {
      let status = buildStatusFromAlerts(routeData.response.alerts);
      if (status === "SERVICE_CHANGE") {
        color = "Orange";
      } else if (status === "DELAYS") {
        color = "Red";
      } else if (status === "GOOD_SERVICE") {
        color = "Green";
      } else {
        color = "Red";
      }
    }
  } else {
    color = "Gray";
  }

  statusClasses += " " + color;

  if (props.addLinks) {
    return(
      <div key={routeId} className="logoWrapper">
        <Link to={"/routes/" + routeId}>
          <div className={statusClasses} />
          <RouteLogo route={routeId} />
        </Link>
      </div>
    );
  } else {
    return(
      <div key={routeId} className="logoWrapper">
        <div className={statusClasses} />
        <RouteLogo route={routeId} />
      </div>
    );
  }
}

export function sortRouteIds(routeIds: string[]): string[] {
  let routeIdsSet = new Set(routeIds);
  let allGroups = [
    ["1", "2", "3"],
    ["4", "5", "6"],
    ["A", "C", "E"],
    ["B", "D", "F", "M"],
    ["N", "Q", "R", "W"],
    ["J", "Z"],
  ];
  let activeGroups: string[][] = [];
  for (const routeIds of allGroups) {
    let activeGroup: string[] = [];
    for (const routeId of routeIds) {
      if (!routeIdsSet.has(routeId)) {
        continue;
      }
      activeGroup.push(routeId);
      routeIdsSet.delete(routeId);
    }
    if (activeGroup.length > 0) {
      activeGroups.push(activeGroup);
    }
  }
  for (const routeId of routeIdsSet) {
    activeGroups.push([routeId]);
  }
  activeGroups.sort(function (lhs, rhs) {
    if (lhs[0] < rhs[0]) {
      return -1;
    }
    return 1;
  });
  let result: string[] = [];
  for (const group of activeGroups) {
    for (const routeId of group) {
      result.push(routeId);
    }
  }
  return result;
}
