import React, { useEffect, useState } from "react";

import "./HubPage.css";

import ListOfRouteLogos from "../elements/routelogo/ListOfRouteLogos";
import RouteLogo from "../elements/routelogo/RouteLogo";
import { Link } from "react-router-dom";
import { List, ListElement } from "../elements/List";
import { ListStopsReply, Stop, StopTime, Trip_Reference } from "../api/types";
import { useHttpData } from "../hooks/http";
import { stopServiceMapsURL, stopURL } from "../api/api";
import { ErrorMessage, LoadingPanel } from "../elements/BasicPage";
import { FavoriteButton } from "../elements/FavoriteButton";
import ListOfStops from "../elements/ListOfStops";
import { useSettings } from "../hooks/settings";
import { StopPageProps } from "./StopPage";
import StopPage from "./StopPage";
import { DebuggingInformation } from "./AboutPage";

export type HubPageProps = {
  stops: StopPageProps[];
  name: string;
};

function HubPage(props: HubPageProps) {
  const stops = props.stops;
  let children = [];
  for (const stop of stops) {
    children.push(
      <div>
        <StopPage
        key={"stop" + stop.stopId}
        stopId={stop.stopId}
        stopName={stop.stopName}
        extraUsualRouteIds={stop.extraUsualRouteIds}
        />
        <hr/>
      </div>
      )
  }
  return (
    <div className="HubPage">
      <h1>{props.name}   <a className="reload" onClick={() => window.location.reload ()} href="#">[&#x21bb;]</a></h1>
      <hr/>
      <div>{children}</div>
      <h1>Debugging Info</h1>
      <DebuggingInformation/>
    </div>)
}

export default HubPage;